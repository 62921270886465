// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/constants.ts"
);
import.meta.hot.lastModified = "1742928335445.917";
}
// REMIX HMR END

export const APP_META_TITLE = 'Europal';
export const APP_META_DESCRIPTION =
  'A headless commerce storefront starter kit built with Remix & Vendure';
export const DEMO_API_URL = 'https://readonlydemo.vendure.io/shop-api';
